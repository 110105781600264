@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

$v2-green: #26CFAD;
$v2-yellow: #FCBF17;
$v2-gray-medium: #8E8E8E;
$v2-black: #0E0E0E;

@import "./scanScreen.scss";
@import "./chatScreen.scss";

div[class^="styles_bannerTop"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

img[class^="styles_bannerIcon"] {
  object-fit: contain;
}
