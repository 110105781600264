.download-app-banner {
  position: fixed;
}

#turnYourDevice {
  display: none;
  height: 100vh;
  width: 100vw;
  background-color: $v2-black;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;

  img {
    width: 150px;
    height: 150px;
    object-fit: contain;
  }

  p {
    color: white;
    font-size: 1.25rem;
    max-width: 300px;
  }

}

#searching-ocode {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($v2-black, .8);
  z-index: 5;

  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}

.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid $v2-green;
  animation: prixClipFix 2s linear infinite;
}

.loader::after{
  inset: 8px;
  transform: rotate3d(90, 90, 0, 180deg );
  border-color: $v2-yellow;
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
}

#canvas { max-height: 100vh }

#scan-arrow {
  transform: rotate(90deg);
  margin: .5rem 0;
}

#scan-text-ocode {
  color: $v2-gray-medium;
  width: 250px;
  text-align: center;
  font-size: 1rem;
  line-height: 1.2em;

  .text-big {
    font-size: 2rem;
    font-weight: 600;
    color: $v2-green;
  }
}

#scan-ocode-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background-color: $v2-black;
  color: white;
  text-align: center;
  z-index: 5;
}

#scan-target {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    width: 150px;
    height: 150px;
  }

  p {
    position: absolute;
    bottom: -4rem;
    left: 0;
    margin-top: 2rem;
    color: white;
    font-weight: 600;
    width: 100%;
  }
}

.scan-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url(/bg.png);
  background-size: cover
}

.unavailableOCodeWindow, .scan-wrapper {
  width: 100%;
  min-height: 100vh;
  background: $v2-black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: 0.3s ease-in-out;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  padding: 3rem 0 0;
  // background-image: url(/Phone.svg);
  // background-size: 100% 100%;
}

.unavailableOCodeModel {
  padding: 1.5rem 1rem;
  border-radius: 10px;
  background-color: white;
  color: $v2-black;

  h2 {
    font-size: 1.4rem;
  }
}

.unavailableOCodeWindow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; 
  background-color: rgba($v2-black, .4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  z-index: 4;
}

.unavailableOCodeButton {
  padding: 1rem;
  background-color: $v2-green;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  text-align: center;
  transition: background-color .3s ease-in-out;
  &:hover {
    color: white;
    text-decoration: none;
    background-color: darken($color: $v2-green, $amount: 5%)
  }
}

.scan-button {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  padding: 1.25rem;
  background-color: transparent;
  border: solid 2px rgba($v2-gray-medium, .4);
  cursor: pointer;
}

.phone-background { display: none; }

@media (min-width: 481px) {

  #scan-ocode-title {
    top: 8px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    padding-top: 35px;
  }

  #searching-ocode { border-radius: 50px; }

  #live {
    border-radius: 50px;
  }

  .scan-container {
    min-height: 100vh;
    overflow: auto;
    padding: 3rem;
    height: unset;
  }

  .unavailableOCodeWindow, .scan-wrapper {
    position: relative;
    right: unset;
    bottom: unset;
    overflow: unset;
    width: 290px;
    // height: calc(100vh - 120px);
    min-height: unset;
    height: 632px;
    border-radius: 50px;
    transform: unset;
    box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, .3);
  }

  .unavailableOCodeWindow {
    box-shadow: none;
    position: absolute;
  }

  .phone-background {
    display: block;
    position: absolute;
    top: 0;
    left: -13px;
    width: 313px;
    height: 100%;
    max-width: initial;
    pointer-events: none;
    z-index: 6;
  }

}

@media (hover:none) and (orientation: landscape) {
  #root { display: none; }
  #turnYourDevice { display: flex; }
}
