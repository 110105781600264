body {
  background-image: url(/bg.png);
  background-size: cover;
}

#waiting-text {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  padding: 2rem;
  background-color: rgba(255, 255, 255, .85);
  color: $v2-green;
  text-align: center;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    bottom: -150px;
    left: 50%;
    height: 150px;
    width: 4px;
    border-left: dashed 2px #000;
  }
}

.sc-header {
  background-color: $v2-black;
}

.sc-header--team-name {
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
}

.sc-header--img {
  cursor: pointer;
  display: inline-block !important;
}

.sc-user-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  z-index: 5;
  
  &.active {
    background-color: #f4f7f9;
  }
}

.sc-user-input--text {
  width: 260px;
  background-color: white;
  padding: 10px;
  border-radius: 999rem;
  cursor: text;
}

.sc-user-input--buttons {
  position: relative;
  width: auto;
  right: unset;
  display: block;
  height: auto;
}

.sc-message-list {
  background-color: #FEFEFE;
}

.msgTextFinder {
  background-color: $v2-yellow;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  padding: 14px 16px;
  margin-bottom: 10px;
  font-weight: 500;
}

.msgTextOwner {
  background-color: white;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  padding: 14px 16px;
  color: $v2-black;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, .15);
  margin-bottom: 10px;
  font-weight: 500;
}

@media (min-width: 481px) {

  .sc-header {
    padding-top: 40px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }

  .sc-chat-window {
    position: relative;
    width: 290px;
    max-height: 632px !important;
    height: 632px !important;
    border-radius: 50px;
    right: initial !important;
    left: initial;
    top: initial;
    bottom: initial;
    margin: auto;
  }

  .sc-user-input--text {
    width: 200px;
    padding: 5px 10px;
  }

  .sc-user-input {
    bottom: 15px;
    min-height: 70px;
  }

}
