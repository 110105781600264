.sc-launcher {
  display:none !important;
}

.sc-chat-window {
  right:calc(50% - 185px) !important;
  max-height: calc(100% - 200px) !important;
}

@media (max-width: 900px) {
  .sc-chat-window {
    max-height: 100% !important;
  }
}

@media (max-width: 450px) {
  .sc-chat-window {
    right:0 !important;
  }
}

.sc-header--close-button {
  opacity: 0;
  pointer-events: none;
}

// .sc-header--img {
//   display:none !important;
// }

.sc-header--team-name {
  text-align: center;
  font-size: 1.4em;
}

// .sc-header {
//   background: rgba(41,159,198,1);
// }

.sc-message {
  width:auto;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 0;
}

.sc-message--content.sent .sc-message--text {
  background-color: transparent;
  max-width: calc(100% - 40px);
  word-wrap: break-word;
}

.sc-message--text {
  width:auto;
  border-radius:20px;
  padding:0;
}

.sc-user-input--file-icon-wrapper {
  // display: none !important;
}

.sc-user-input--file-icon, .sc-user-input--send-icon {
  width:60px !important;
  height:60px !important;
}

.sc-message--avatar {
  display:none !important;
}

.sc-message--content.received .sc-message--text {
  color: #FFF;
  background-color: transparent;
  margin-right:0;
}

.msgTextOwner {
  background: rgba(41,159,198,1);
  border-radius:20px;
  padding:10px 16px;
}

.msgTextFinder {
  background: rgba(235,131,29,1);
  border-radius:20px;
  padding:10px 16px;
}

@media (max-width: 930px) {
 .textRight1 {
   padding:10px !important;
   font-size: 3em !important;
 }
 .textRight2 {
   padding:10px !important;
   font-size: 1.5em !important;
 }
 .textRight3 {
   padding:10px !important;
   font-size: 1em !important;
 }
}
